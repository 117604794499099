@font-face {
  font-family: 'Museo700';
  src: url('./assets/fonts/Museo700-Regular-webfont.eot');
  src: url('./assets/fonts/Museo700-Regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Museo700-Regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/Museo700-Regular-webfont.woff') format('woff'),
    url('./assets/fonts/Museo700-Regular-webfont.ttf') format('truetype'),
    url('./assets/fonts/Museo700-Regular-webfont.svg#museo700') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Museo500';
  src: url('./assets/fonts/Museo500-Regular-webfont.eot');
  src: url('./assets/fonts/Museo500-Regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Museo500-Regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/Museo500-Regular-webfont.woff') format('woff'),
    url('./assets/fonts/Museo500-Regular-webfont.ttf') format('truetype'),
    url('./assets/fonts/Museo500-Regular-webfont.svg#museo500') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Museo300';
  src: url('./assets/fonts/Museo300-Regular-webfont.eot');
  src: url('./assets/fonts/Museo300-Regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Museo300-Regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/Museo300-Regular-webfont.woff') format('woff'),
    url('./assets/fonts/Museo300-Regular-webfont.ttf') format('truetype'),
    url('./assets/fonts/Museo300-Regular-webfont.svg#museo300') format('svg');
  font-weight: normal;
  font-style: normal;
}
