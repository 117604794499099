/*** Hide elements to be revealed by animation later ***/
/* Nav */
#vertical-nav-bar-interests,
#vertical-nav-bar-study-level,
#vertical-nav-bar-itrs,
#vertical-nav-bar-course,
#vertical-nav-bar-pathway,
/* Interests page */
.interests-page-content-tile,
#interests-page-footer,
/* Study Level page */
.study-level-page-content-tile,
#study-level-page-footer,
#study-level-page-content-link,
/* Interest page */
#itrs-page-sidebar,
#itrs-page-sidebar-other-header,
#itrs-page-related,
#itrs-page-header-text,
#itrs-page-header-icon,
#itrs-page-other,
#itrs-page-sidebar-top-header,
.course-card-list-item,
#itrs-page-courses-top-p,
#itrs-page-courses-gp-notification,
#itrs-page-courses-ve-filter,
#itrs-page-study-level-modal-btn,
#itrs-page-courses-bottom-header,
#itrs-page-courses-bottom-p,
#itrs-page-courses-bottom-ul,
#itrs-page-courses-show-more-button,
#itrs-page-courses-hr,
#itrs-page-contact-container,
/* Course page */
#course-page-header-text,
#course-page-header-icon,
#course-page-sidebar-header,
.course-page-sidebar-item,
#course-page-content-header,
#course-page-content-p,
#course-page-content-gp-notification,
#course-page-content-gp-filter,
.course-page-pathway-item,
#course-page-content-disclaimer,
#course-page-content-contact,
/* Pathway page */
#pathway-page-sidebar-header,
#pathway-page-header-icon,
#pathway-page-sidebar-header,
.pathway-page-pathway-item,
#pathway-page-content-share,
#pathway-page-content-top-header,
#pathway-page-content-top-subheader,
#pathway-page-content-top-gp,
#pathway-page-content-top-p,
#pathway-page-content-second-p,
#pathway-page-content-how-to,
.pathway-page-content-pathway-item,
#pathway-page-content-disclaimer,
#pathway-page-content-hr,
#pathway-page-content-contact {
  opacity: 0;
}

#itrs-page-related,
#itrs-page-other {
  overflow: hidden;
  padding-bottom: 1px;
}
